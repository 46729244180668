import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/Layout.js";
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ExternalLink from '../components/ExternalLink/ExternalLink';
import SEO from '../components/SEO/seo';
import TransitionLink from '../components/TransitionLink/TransitionLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Now" mdxType="SEO" />
    <p>{`Welcome to my now page! This is a quick update on what I've been up to lately.`}</p>
    <h1>{`Work`}</h1>
    <p>{`I am enjoying my new role at `}<ExternalLink href="https://www.rippling.com" text="Rippling" mdxType="ExternalLink" />{`, a leading HR platform that helps businesses streamline their payroll, benefits, and HR processes with centralized employee data. As part of the performance management team, I'm focused on building products that help facilitate conversations between employees and managers on how they can increase their impact and achieve their career goals.`}</p>
    <h1>{`Fitness`}</h1>
    <p>{`I recently started the Build Your Power Zone program on Peloton, and I've been making an effort to play tennis weekly. I'm still rocking my trusty `}<ExternalLink href="https://www.yonex.com/tennis/racquets/ezone/07ez100" text="Yonex Ezone 100 2022 racket" wrap mdxType="ExternalLink" />{` with a `}<ExternalLink href="https://www.wilson.com/en-us/product/nxt-rz94270" text="Wilson NXT 16 string" wrap mdxType="ExternalLink" />{`.`}</p>
    <h1>{`Recreation`}</h1>
    <p>{`I got back from my first trip to Yosemite not long ago, where I had the opportunity to do some wonderful hikes. Can't wait to go back for Half Dome!`}</p>
    <h2>{`Reading`}</h2>
    <p>{`If you're curious about what I've been reading lately, check out my reading list `}<TransitionLink to="/reading" mdxType="TransitionLink">{`here`}</TransitionLink>{`.`}</p>
    <h2>{`Last updated`}</h2>
    <p>{`Oct 16th, 2024 from New York City.`}</p>
    <p>{`This page was inspired by Derek Sivers and the `}<ExternalLink href="https://www.nownownow.com/about" text="NowNowNow" mdxType="ExternalLink" />{` movement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      